



















































































































































































































































































































  /*
	todo:
	- sort does not work properly with reset
	- sort desc is weird
	- does not set limit/page in url
	*/

  import Vue from 'vue'
  import { Component, Watch } from 'vue-property-decorator'
  import { appendSitePrefix } from '@/utils/routeUtils'

  @Component
  export default class SupplierInvoices extends Vue {
    loading = false
    pagination = {
      descending: true,
      page: 1,
      itemsPerPage: 50,
      sortBy: ['invoiceDate']
    }

    suppliers = []
    approvalStatuses = []
    totalItems = null
    orderId = null
    items = []
    ids = []
    filterSupplierId = null
    filterIsValid = null
    filterIsDue = null
    filterIsCredit = null
    filterIsSentToErp = null
    filterApprovalStatus = null
    filterHasEontyreOrders = null
    filterFromInvoiceDate = null
    filterToInvoiceDate = null
    filterInvoiceNumber = null
    filterOrderNumber = null
    filterTags = null
    filterHasShipping = null
    filterHideInvoicesBeforeErpIntegrationStartDate = null
    search = false
    tags = []

    appendSitePrefix = appendSitePrefix

    get isFilterInvoiceNumberInFocus() {
      return !!this.filterInvoiceNumber || !!this.filterOrderNumber
    }

    get headers() {
      return [
        { text: this.$t('Supplier ID'), value: 'supplierId' },
        { text: this.$t('Type'), value: 'typeName' },
        { text: this.$t('Invoice number'), value: 'invoiceNumber' },
        { text: this.$t('Invoice date'), value: 'invoiceDate' },
        { text: this.$t('Due date'), value: 'dueDate' },
        { text: this.$t('Amount'), value: 'grossAmount' },
        { text: this.$t('IsValid'), value: 'isValid' },
        { text: this.$t('IsSentToErp'), value: 'isSentToErp', sortable: false },
        { text: this.$t('ApprovalStatus'), value: 'approvalStatus', sortable: false },
        { text: this.$t('EontyreOrders'), value: 'eontyreOrderIds', sortable: false },
        { text: this.$t('Supplier order number'), value: 'supplierOrderNumber' },
        { text: this.$t('Tags'), value: 'tags' },
        { text: this.$t('Actions'), value: 'actions', sortable: false },
      ].filter((i) => !!i)
    }

    mounted() {
      const q = this.$route.query

      this.filterSupplierId = q.supplier ? parseInt('' + q.supplier) : null
      this.filterInvoiceNumber = q.invoiceNumber
      this.filterOrderNumber = q.orderNumber
      this.filterFromInvoiceDate = q.fromInvoiceDate
      this.filterToInvoiceDate = q.toInvoiceDate
      this.filterIsValid = this.queryParamToFilterBool(q.isValid)
      this.filterIsDue = this.queryParamToFilterBool(q.isDue)
      this.filterIsCredit = this.queryParamToFilterBool(q.isCredit)
      this.filterIsSentToErp = this.queryParamToFilterBool(q.isSentToErp)
      this.filterApprovalStatus = this.queryParamOptionInt(q.approvalStatus)
      this.filterHasEontyreOrders = this.queryParamToFilterBool(q.hasEontyreOrders)
      this.filterHasShipping = this.queryParamToFilterBool(q.hasShipping)
      this.filterTags = this.queryParamsToTags(q.tags)
      this.filterHideInvoicesBeforeErpIntegrationStartDate = this.queryParamToFilterBool(
        q.hideInvoicesBeforeErpIntegrationStartDate,
      )
      this.search = this.queryParamToFilterBool(q.search)

      this.getTags()

      this.$axios.get('/v3/suppliers/invoices/get_initial_data?include_all_option=true').then((response) => {
        this.suppliers = response.data.data.suppliers
        this.approvalStatuses = response.data.data.approvalStatuses
      })
    }

    queryParamToFilterBool(value) {
      if (value === undefined || value === null) {
        return null
      }
      if (value && value !== 'false' && value !== '0') {
        return true
      }
      return false
    }

    queryParamFromFilterBool(value) {
      if (value === undefined || value === null) {
        return null
      }
      return value ? '1' : '0'
    }

    queryParamsToTags(value) {
      const result = []
      if (!value) {
        return result
      }
      const items = value.split(',')
      for (let i = 0; i < items.length; i++) {
        const item = parseInt(items[i].trim())
        if (item) {
          result[result.length] = item
        }
      }
      return result
    }

    queryParamOptionInt(value) {
      if (value === undefined || value === null) {
        return null
      }
      return parseInt(value)
    }

    toExcel() {
      this._fetchItems(true)
    }

    fetchItems() {
      this._fetchItems(false)
    }

    _fetchItems(toExcel) {
      if (!this.search) {
        return
      }

      this.loading = true

      this.$vuetify.goTo(0, { easing: 'easeInOutQuad', duration: 150 })
      let url = '/v3/suppliers/invoices?page=' + (this.pagination.page || '1')

      if (this.pagination.itemsPerPage) {
        url += '&perPage=' + this.pagination.itemsPerPage
      }

      if (this.pagination.sortBy) {
        url += '&sort=' + this.pagination.sortBy
      }

      // todo: omg.. sortDesc acts weird
      let foo: any = {}
      foo = this.pagination
      // let str = '' + this.pagination.sortDesc
      const str = '' + foo.sortDesc
      if (str === 'true') {
        url += '&desc=1'
      }

      if (this.filterSupplierId) {
        url += '&supplier=' + this.filterSupplierId
      }
      if (this.filterInvoiceNumber || this.filterOrderNumber) {
        if (this.filterInvoiceNumber) {
          url += '&invoiceNumber=' + this.filterInvoiceNumber
        }
        if (this.filterOrderNumber) {
          url += '&orderNumber=' + this.filterOrderNumber
        }
      } else {
        if (this.filterIsValid !== null) {
          url += '&isValid=' + this.queryParamFromFilterBool(this.filterIsValid)
        }
        if (this.filterIsSentToErp !== null) {
          url += '&isSentToErp=' + this.queryParamFromFilterBool(this.filterIsSentToErp)
        }
        if (this.filterIsDue !== null) {
          url += '&isDue=' + this.queryParamFromFilterBool(this.filterIsDue)
        }
        if (this.filterIsCredit !== null) {
          url += '&isCredit=' + this.queryParamFromFilterBool(this.filterIsCredit)
        }
        if (this.filterHasShipping !== null) {
          url += '&hasShipping=' + this.queryParamFromFilterBool(this.filterHasShipping)
        }
        if (this.filterFromInvoiceDate) {
          url += '&fromInvoiceDate=' + this.filterFromInvoiceDate
        }
        if (this.filterToInvoiceDate) {
          url += '&toInvoiceDate=' + this.filterToInvoiceDate
        }
        if (this.filterTags) {
          url += '&tags=' + this.filterTags.join(',')
        }
        if (this.filterApprovalStatus !== null) {
          url += '&approvalStatus=' + this.queryParamOptionInt(this.filterApprovalStatus)
        }
        if (this.filterHasEontyreOrders !== null) {
          url += '&hasEontyreOrders=' + this.queryParamFromFilterBool(this.filterHasEontyreOrders)
        }
        if (this.filterHideInvoicesBeforeErpIntegrationStartDate !== null) {
          url +=
            '&hideInvoicesBeforeErpIntegrationStartDate=' +
            this.queryParamFromFilterBool(this.filterHideInvoicesBeforeErpIntegrationStartDate)
        }
      }

      if (toExcel) {
        url += '&xlsx=1'
      }

      this.$axios.get(url).then((response) => {
        if (toExcel) {
          const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
            const byteCharacters = atob(b64Data)
            const byteArrays = []

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
              const slice = byteCharacters.slice(offset, offset + sliceSize)

              const byteNumbers = new Array(slice.length)
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
              }

              const byteArray = new Uint8Array(byteNumbers)
              byteArrays.push(byteArray)
            }

            return new Blob(byteArrays, { type: contentType })
          }
          const blob = b64toBlob(response.data.data, 'application/ms-excel')
          const blobUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = blobUrl
          link.setAttribute('download', 'file.xlsx')
          document.body.appendChild(link)
          link.click()
        } else {
          this.totalItems = response.data.meta.total_items
          this.items = response.data.data ? response.data.data.map(this.parseItems) : []
          this.ids = []
        }
        this.loading = false
      })
    }

    @Watch('pagination')
    onPaginationChange() {
      this.fetchItems()
    }

    parseItems(item) {
      item.supplierName = ''
      if (item.supplierId) {
        item.supplierName = '(' + item.supplierId + ')'
        for (let i = 0; i < this.suppliers.length; i++) {
          if (this.suppliers[i].id === item.supplierId) {
            item.supplierName = this.suppliers[i].name
          }
        }
        item.isSentToErp = item.erpStatus && item.erpStatus === 2 // todo: const
      }

      item.typeName = item.isCredit ? 'Credit' : 'Invoice'

      return item
    }

    resetSearch() {
      this.filterSupplierId = null
      this.filterIsValid = null
      this.filterIsDue = null
      this.filterIsCredit = null
      this.filterIsSentToErp = null
      this.filterFromInvoiceDate = null
      this.filterToInvoiceDate = null
      this.filterInvoiceNumber = null
      this.filterOrderNumber = null
      this.filterHasShipping = null
      this.filterTags = []
      this.filterApprovalStatus = null
      this.filterHasEontyreOrders = null
      this.filterHideInvoicesBeforeErpIntegrationStartDate = null
      this.gotoRoute(false)
    }

    setThisMonth() {
      const date = new Date()
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      const month = date.getMonth() + 1
      const monthStr = month < 10 ? '0' + month : '' + month
      const firstDayStr = '0' + firstDay.getDate()
      const lastDayStr = lastDay.getDate()
      this.filterFromInvoiceDate = date.getFullYear() + '-' + monthStr + '-' + firstDayStr
      this.filterToInvoiceDate = date.getFullYear() + '-' + monthStr + '-' + lastDayStr
      this.gotoRoute()
    }

    setPrevMonth() {
      const date = new Date()
      date.setDate(1)
      date.setMonth(date.getMonth() - 1)
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
      const month = date.getMonth() + 1
      const monthStr = month < 10 ? '0' + month : '' + month
      const firstDayStr = '0' + firstDay.getDate()
      const lastDayStr = lastDay.getDate()
      this.filterFromInvoiceDate = date.getFullYear() + '-' + monthStr + '-' + firstDayStr
      this.filterToInvoiceDate = date.getFullYear() + '-' + monthStr + '-' + lastDayStr
      this.gotoRoute()
    }

    gotoRoute(search: boolean = true) {
      this.pagination.page = 1
      this.search = search
      const params = {
        supplier: this.filterSupplierId,
        invoiceNumber: this.filterInvoiceNumber,
        orderNumber: this.filterOrderNumber,
        isValid: this.queryParamFromFilterBool(this.filterIsValid),
        isSentToErp: this.queryParamFromFilterBool(this.filterIsSentToErp),
        isDue: this.queryParamFromFilterBool(this.filterIsDue),
        isCredit: this.queryParamFromFilterBool(this.filterIsCredit),
        hasShipping: this.queryParamFromFilterBool(this.filterHasShipping),
        approvalStatus: this.queryParamOptionInt(this.filterApprovalStatus),
        hasEontyreOrders: this.queryParamFromFilterBool(this.filterHasEontyreOrders),
        hideInvoicesBeforeErpIntegrationStartDate: this.queryParamFromFilterBool(
          this.filterHideInvoicesBeforeErpIntegrationStartDate,
        ),
        fromInvoiceDate: this.filterFromInvoiceDate,
        toInvoiceDate: this.filterToInvoiceDate,
        tags: this.filterTags ? this.filterTags.join(',') : '',
        search: this.queryParamFromFilterBool(this.search),
      }
      const query = {}
      for (const key in params) {
        if (params[key] !== null) {
          query[key] = params[key]
        }
      }
      this.$router.push({
        name: 'Suppliers/Invoices',
        query: query,
      })
      this.fetchItems()
    }

    getTag(tagId) {
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].id === tagId) {
          return this.tags[i]
        }
      }
    }

    getTagCode(tagId) {
      if (!tagId) {
        return ''
      }
      const tag = this.getTag(tagId)
      if (!tag || !tag.name) {
        return ''
      }
      const result = []
      const parts = tag.name.split(/[- ]/)
      for (let i = 0; i < parts.length; i++) {
        result[result.length] = parts[i][0]
      }
      return result.join('').toUpperCase()
    }

    getTagColor(tagId) {
      if (!tagId) {
        return ''
      }
      const tag = this.getTag(tagId)
      return tag?.color ? tag.color : ''
    }

    getTagName(tagId) {
      if (!tagId) {
        return ''
      }
      const tag = this.getTag(tagId)
      return tag?.name ? tag.name : ''
    }

    getTags() {
      this.$axios
        .get('/v3/suppliers/invoice-tags')
        .then((response) => {
          this.tags = response.data.data
        })
        .catch((err) => {
          console.error('Failed to fetch tags: ', err)
        })
    }

    removeTag(item) {
      const index = this.filterTags.indexOf(item.id)
      if (index >= 0) this.filterTags.splice(index, 1)
    }
  }
